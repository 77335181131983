import { LOGIN, LOGOUT } from "./action";

const defaultState = {
    isLogin: true,
};

const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                ...{
                    isLogin: action.isLogin,
                }
            }
        case LOGOUT:
            return {
                ...state,
                isLogin: true,
            }
        default:
            return state
    }
}

export default authReducer;