/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import classNames from "classnames";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { useScrollSection } from 'react-scroll-section';

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import logo_img from "../../assets/images/logo.png";
var ps;
const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const appendix_bank = useScrollSection('appendix_bank');
  const appendix_currency = useScrollSection('appendix_currency');
  const appendix_country_code = useScrollSection('appendix_country_code');
  const deposit = useScrollSection('deposit');
  const deposit_url = useScrollSection('deposit_url');
  const deposit_data = useScrollSection('deposit_data');
  const deposit_signature = useScrollSection('deposit_signature');
  const deposit_http = useScrollSection('deposit_http');
  const deposit_argument = useScrollSection('deposit_argument');
  const deposit_post = useScrollSection('deposit_post');
  const checkstring2 = useScrollSection('checkstring2');
  const deposit_check_redirect = useScrollSection('deposit_check_redirect');
  const deposit_check_redirect_url = useScrollSection('deposit_check_redirect_url');
  const deposit_check_data_posted = useScrollSection('deposit_check_data_posted');
  const deposit_check_check_string = useScrollSection('deposit_check_check_string');
  const deposit_check_http_redirected = useScrollSection('deposit_check_http_redirected');
  const deposit_check_btb = useScrollSection('deposit_check_btb');
  const deposit_check_btb_url = useScrollSection('deposit_check_btb_url');
  const deposit_check_btb_data = useScrollSection('deposit_check_btb_data');
  const deposit_check_btb_check_string = useScrollSection('deposit_check_btb_check_string');
  const deposit_check_btb_http_redirect = useScrollSection('deposit_check_btb_http_redirect');
  const payout_redirect = useScrollSection('payout_redirect');
  const payout_redirect_url = useScrollSection('payout_redirect_url');
  const payout_redirect_data = useScrollSection('payout_redirect_data');
  const payout_redirect_signature = useScrollSection('payout_redirect_signature');
  const payout_redirect_http = useScrollSection('payout_redirect_http');
  const payout_redirect_json = useScrollSection('payout_redirect_json');
  const payout_btb = useScrollSection('payout_btb');
  const payout_btb_url = useScrollSection('payout_btb_url');
  const payout_btb_data = useScrollSection('payout_btb_data');
  const payout_btb_signature = useScrollSection('payout_btb_signature');
  const payout_btb_json_return = useScrollSection('payout_btb_json_return');
  const payout_btb_json_sent = useScrollSection('payout_btb_json_sent');
  const payout_status = useScrollSection('payout_status');
  const payout_status_url = useScrollSection('payout_status_url');
  const payout_status_data = useScrollSection('payout_status_data');
  const payout_status_json = useScrollSection('payout_status_json');
  const deposit_refund = useScrollSection('deposit_refund');
  const deposit_refund_url = useScrollSection('deposit_refund_url');
  const deposit_refund_data = useScrollSection('deposit_refund_data');
  const deposit_refund_check_string = useScrollSection('deposit_refund_check_string');
  const deposit_refund_http = useScrollSection('deposit_refund_http');
  const deposit_appeal = useScrollSection('deposit_appeal');
  const deposit_appeal_url = useScrollSection('deposit_appeal_url');
  const deposit_appeal_data = useScrollSection('deposit_appeal_data');
  const deposit_appeal_check_string = useScrollSection('deposit_appeal_check_string');
  const deposit_appeal_http = useScrollSection('deposit_appeal_http');
  
  const [collapsedMenu, setCollapsedMenu] = useState({
    deposit: false,
    deposit_status_check: false,
    deposit_status_btb: false,
    deposit_appeal: false,
    payout_direct: false,
    payout_btb: false,
    payout_status_btb: false,
    deposit_refund: false,
    appendix: false
  });

  const toggle = (menu) => {
    let data = { ...collapsedMenu };
    data[menu] = !data[menu];
    for (const key in data) {
      if(key !== menu){
        data[key] = false;
      }
    }
    setCollapsedMenu(data);
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt="logo"
              className="navbar-brand-img"
              src={logo_img}
            />
          </NavbarBrand>
        ) : null}
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to="/">
                      <img alt={'logo'} src={logo_img} />
                    </Link>
                  ) : (
                    <a href="/">
                      <img alt={'logo'} src={logo_img} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
         
          {/* Navigation */}
          {/* <Nav navbar>
           
            {createLinks(routes)}
          </Nav> */}
          
          <hr className="my-2" />
          <div className="text-left d-md-none" style={{ width: '100%'}}>
            <a className="btn-primary btn btn-home" href="https://metapay168.net/">Metapay Home Page</a>
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
