export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export function loginConfirmedAction(data) {
    return {
        type: LOGIN,
        isLogin: data,
    };
}

export function logout(history) {
    localStorage.removeItem('metapay');
    return {
        type: LOGOUT,
    };
}