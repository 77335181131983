import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container
} from "reactstrap";
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import "../../assets/css/login.css";
  
const Login = () => {
    const dispatch = useDispatch();
    const [password, setPassword] = useState("")
    const [error, setError] = useState('')

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPassword(value);
    }

    function unlock(){
        if(password){
            if(password !== 'metapay1688'){
                setError('Invalid password.')
            }else{
                setError('');
                let tokenDetails = { login: true };
                tokenDetails.expireDate = new Date(
                    new Date().getTime() + 3600 * 1000,
                );
                localStorage.setItem('metapay', JSON.stringify(tokenDetails));
                dispatch({
                    type: "LOGIN",
                    isLogin: true,
                });
            }
        }else{
            setError('Please enter password.')
        }
    }

    return (
      <>
        <Container className="login-container">
            <div className="login-inner-container">
                <Row className="justify-content-center align-items-center">
                    <Col lg="5" md="7">
                        <Card className="bg-secondary shadow border-0">
                            <CardHeader className="bg-transparent py-5 text-center">
                                <img src={require('../../assets/images/logo.png').default} className="img-fluid login-logo" />
                            </CardHeader>   
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form">
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="new-password"
                                            value={password}
                                            onChange={e => handleInputChange(e)}
                                        />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="pb-5 text-center">
                                        <span className="error">{error}</span>
                                    </div>
                                    <div className="text-center">
                                        <Button color="primary" type="button" onClick={()=>unlock()}>
                                            Unlock
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
      </>
    );
  };
  
  export default Login;
  