import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Container,
    Table
} from "reactstrap";
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import "../../assets/css/login.css";

const list = [
    { id: 1, name: 'BRL PG Commercial - 19082022.pdf', url: 'https://metapay168.net/document/BRL%20PG%20Commercial%20-%2019082022.pdf' },
    // { id: 2, name: 'CNY PG B2B Commercial - 29082022.pdf', url: 'https://metapay168.net/document/CNY%20PG%20B2B%20Commercial%20-%2029082022.pdf' },
    { id: 3, name: 'CNY PG Commercial - 29082022.pdf', url: 'https://metapay168.net/document/CNY%20PG%20Commercial%20-%2029082022.pdf' },
    { id: 4, name: 'Crypto PG Commercial - 09092022.pdf', url: 'https://metapay168.net/document/Crypto%20PG%20Commercial%20-%2009092022.pdf' },
    // { id: 5, name: 'Global PG B2C Commercial - 19082022.pdf', url: 'https://metapay168.net/document/Global%20PG%20B2C%20Commercial%20-%2019082022.pdf' },
    { id: 6, name: 'HKD PG Commercial - 11082022.pdf', url: 'https://metapay168.net/document/HKD%20PG%20Commercial%20-%2011082022.pdf' },
    { id: 7, name: 'IDR PG Commercial - 25082022.pdf', url: 'https://metapay168.net/document/IDR%20PG%20Commercial%20-%2025082022.pdf' },
    { id: 8, name: 'INR PG Commercial - 29082022.pdf', url: 'https://metapay168.net/document/INR%20PG%20Commercial%20-%2029082022.pdf' },
    { id: 9, name: 'JPY PG Commercial - 27062022.pdf', url: 'https://metapay168.net/document/JPY%20PG%20Commercial%20-%2027062022.pdf' },
    { id: 10, name: 'MYR PG API Commercial - 09092022.pdf', url: 'https://metapay168.net/document/MYR%20PG%20API%20Commercial%20-%2009092022.pdf' },
    { id: 11, name: 'MYR PG WL Commercial - 09092022.pdf', url: 'https://metapay168.net/document/MYR%20PG%20WL%20Commercial%20-%2009092022.pdf' },
    { id: 12, name: 'PHP PG Commercial - 19082022.pdf', url: 'https://metapay168.net/document/PHP%20PG%20Commercial%20-%2019082022.pdf' },
    { id: 13, name: 'SEA PG API Commercial - 09092022.pdf', url: 'https://metapay168.net/document/SEA%20PG%20API%20Commercial%20-%20%2009092022.pdf' },
    // { id: 14, name: 'SEA PG B2B Commercial - 09092022.pdf', url: 'https://metapay168.net/document/SEA%20PG%20B2B%20Commercial%20-%2009092022.pdf' },
    { id: 15, name: 'SEA PG WL Commercial - 09092022.pdf', url: 'https://metapay168.net/document/SEA%20PG%20WL%20Commercial%20-%2009092022.pdf'},
    { id: 16, name: 'SGD PG Commercial - 13062022.pdf', url: 'https://metapay168.net/document/SGD%20PG%20Commercial%20-%2013062022.pdf'},
    { id: 17, name: 'THB PG Commercial - 25082022.pdf', url: 'https://metapay168.net/document/THB%20PG%20Commercial%20-%2025082022.pdf'},
    { id: 18, name: 'VND PG Commercial - 25082022.pdf', url: 'https://metapay168.net/document/VND%20PG%20Commercial%20-%2025082022.pdf'},
];

const Document = () => {
    const dispatch = useDispatch();

    return (
      <>
        <Container>
            <Row className="justify-content-center align-items-center">
                <Col lg="12" md="12">
                    <div className="mt-5" style={{ width: '100%'}}>
                        <a className="btn-primary btn btn-home" href="https://metapay168.net/">Metapay Home Page</a>
                    </div>
                </Col>
            </Row>
            <div className="my-5">
                <Row className="justify-content-center align-items-center">
                    <Col lg="12" md="12">
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Table className="align-items-center table-flush" responsive>
                                    <tbody>
                                        {list.map((item, i)=>(
                                            <tr key={i}>
                                                <td><a href={item.url} target="_blank">{item.name}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                   
                                </Table>  
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
      </>
    );
  };
  
  export default Document;
  