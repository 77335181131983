import { lazy, Suspense, useEffect } from 'react';

/// Components
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter, Redirect } from 'react-router-dom';
// action
import { checkAutoLogin } from '../services/AuthService';
import AdminLayout from "../layouts/Admin";
import Login from './pages/Login';
import Document from './pages/Document';


function App (props) {
    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
        
    }, [dispatch, props.history]);
   
    if (props.isLogin) {
		return (
			<>
            <Switch>
                <Route path="/" component={Document} />
                {/* <Route path="/" render={(props) => <AdminLayout {...props} />} /> */}
                <Redirect to="/" />
            </Switch>
            </>
        );
	
	}else{
		return (
			<>
           <Login />
            </>
		);
	}
};

const mapStateToProps = state => {
    const { auth } = state;
    return {
        isLogin: auth.isLogin
    }
}
export default connect(mapStateToProps)(App);

